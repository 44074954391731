var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _EventRunner_instances, _EventRunner_nextTickTargetTime, _EventRunner_events, _EventRunner_idPosition, _EventRunner_callbackCount, _EventRunner_callbacks, _EventRunner_looperId, _EventRunner_boundLooperCallback, _EventRunner_runReachedEvents;
import SortedMap from 'collections/sorted-map';
import CallbackLooper from './callback-looper';
const UNSET_VALUE = -1;
const nowMillis = Date.now;
const MainLooper = CallbackLooper.main;
let globalInstance;
class EventRunner {
    constructor() {
        _EventRunner_instances.add(this);
        _EventRunner_nextTickTargetTime.set(this, UNSET_VALUE);
        _EventRunner_events.set(this, new SortedMap(null, null, null, function (id) {
            const value = [];
            this.set(id, value);
            return value;
        }));
        _EventRunner_idPosition.set(this, 0);
        _EventRunner_callbackCount.set(this, 0);
        _EventRunner_callbacks.set(this, {});
        _EventRunner_looperId.set(this, UNSET_VALUE);
        _EventRunner_boundLooperCallback.set(this, this.onTick.bind(this));
    }
    static get global() {
        globalInstance !== null && globalInstance !== void 0 ? globalInstance : (globalInstance = new this());
        return globalInstance;
    }
    onTick(_timestamp, now) {
        if (__classPrivateFieldGet(this, _EventRunner_nextTickTargetTime, "f") !== UNSET_VALUE && now >= __classPrivateFieldGet(this, _EventRunner_nextTickTargetTime, "f")) {
            __classPrivateFieldSet(this, _EventRunner_nextTickTargetTime, UNSET_VALUE, "f");
            __classPrivateFieldGet(this, _EventRunner_instances, "m", _EventRunner_runReachedEvents).call(this);
        }
    }
    /**
     * Schedules a callback to be run in {delaySeconds} seconds.
     */
    runInSeconds(callback, delaySeconds) {
        return this.runInMillis(callback, delaySeconds * 1000);
    }
    /**
     * Schedules a callback to be run in {delayMillis} milliseconds.
     */
    runInMillis(callback, delayMillis) {
        return this.runAt(callback, nowMillis() + delayMillis);
    }
    /**
     * Schedules a callback to be run in {delayMillis} milliseconds.
     * This is functionally the same as `runInMillis(Function, number)`
     * and only exists so that this call can be a drop in replacement for `window#setTimeout`.
     */
    setTimeout(callback, delayMillis) {
        return this.runInMillis(callback, delayMillis);
    }
    runAt(callback, timestamp) {
        if (process.env.NODE_ENV === 'development') {
            console.log('runAt', callback, timestamp);
        }
        let callbackId = null;
        if (timestamp <= nowMillis()) {
            if (process.env.NODE_ENV === 'development') {
                console.log('runAt', 'running now');
            }
            callback();
        }
        else {
            __classPrivateFieldSet(this, _EventRunner_idPosition, __classPrivateFieldGet(this, _EventRunner_idPosition, "f") + 1, "f");
            __classPrivateFieldSet(this, _EventRunner_callbackCount, __classPrivateFieldGet(this, _EventRunner_callbackCount, "f") + 1, "f");
            callbackId = __classPrivateFieldGet(this, _EventRunner_idPosition, "f");
            __classPrivateFieldGet(this, _EventRunner_callbacks, "f")[callbackId] = callback;
            __classPrivateFieldGet(this, _EventRunner_events, "f").get(timestamp).push(callbackId);
            if (__classPrivateFieldGet(this, _EventRunner_nextTickTargetTime, "f") === UNSET_VALUE || timestamp < __classPrivateFieldGet(this, _EventRunner_nextTickTargetTime, "f")) {
                __classPrivateFieldSet(this, _EventRunner_nextTickTargetTime, timestamp, "f");
            }
            if (__classPrivateFieldGet(this, _EventRunner_callbackCount, "f") === 1 && !this.isAttachedToLooper) {
                this.addToLooper();
            }
        }
        return callbackId;
    }
    /**
     * Runs the callback and then removes it from the queue.
     */
    runCallback(callbackId) {
        const callback = __classPrivateFieldGet(this, _EventRunner_callbacks, "f")[callbackId];
        if (typeof callback === 'function') {
            callback();
            this.cancel(callbackId);
            return true;
        }
        return false;
    }
    /**
     * Removes the callback from the queue without running in.
     * The run the callback before removal, see `runCallback(number)`.
     * If no more callbacks are scheduled, this will also detach the entire
     * runner from the looper.
     */
    cancel(callbackId) {
        if (!Object.prototype.hasOwnProperty.call(__classPrivateFieldGet(this, _EventRunner_callbacks, "f"), callbackId)) {
            // Without this, the callbackCount will be decremented screwing up the count
            return;
        }
        delete __classPrivateFieldGet(this, _EventRunner_callbacks, "f")[callbackId];
        __classPrivateFieldSet(this, _EventRunner_callbackCount, __classPrivateFieldGet(this, _EventRunner_callbackCount, "f") - 1, "f");
        if (__classPrivateFieldGet(this, _EventRunner_callbackCount, "f") < 1) {
            this.removeFromLooper();
        }
    }
    clearTimeout(callbackId) {
        return this.cancel(callbackId);
    }
    removeFromLooper() {
        if (this.isAttachedToLooper) {
            MainLooper.remove(__classPrivateFieldGet(this, _EventRunner_looperId, "f"));
            __classPrivateFieldSet(this, _EventRunner_looperId, UNSET_VALUE, "f");
        }
    }
    addToLooper() {
        if (!this.isAttachedToLooper) {
            __classPrivateFieldSet(this, _EventRunner_looperId, MainLooper.add(__classPrivateFieldGet(this, _EventRunner_boundLooperCallback, "f")), "f");
        }
    }
    computeNextTickTargetTime() {
        __classPrivateFieldSet(this, _EventRunner_nextTickTargetTime, UNSET_VALUE, "f");
        const now = nowMillis();
        const it = __classPrivateFieldGet(this, _EventRunner_events, "f").keys();
        let result = it.next();
        while (!result.done) {
            const timestamp = result.value;
            if (timestamp > now) {
                __classPrivateFieldSet(this, _EventRunner_nextTickTargetTime, timestamp, "f");
                break;
            }
            result = it.next();
        }
    }
    get isAttachedToLooper() {
        return __classPrivateFieldGet(this, _EventRunner_looperId, "f") !== UNSET_VALUE;
    }
}
_EventRunner_nextTickTargetTime = new WeakMap(), _EventRunner_events = new WeakMap(), _EventRunner_idPosition = new WeakMap(), _EventRunner_callbackCount = new WeakMap(), _EventRunner_callbacks = new WeakMap(), _EventRunner_looperId = new WeakMap(), _EventRunner_boundLooperCallback = new WeakMap(), _EventRunner_instances = new WeakSet(), _EventRunner_runReachedEvents = function _EventRunner_runReachedEvents() {
    if (process.env.NODE_ENV === 'development') {
        console.log('runReachedEvents');
    }
    const now = nowMillis();
    const it = __classPrivateFieldGet(this, _EventRunner_events, "f").iterator();
    let result = it.next();
    while (!result.done) {
        const { key: timestamp, value: callbackIds, } = result.value;
        if (timestamp > now)
            break;
        callbackIds.forEach((callbackId) => this.runCallback(callbackId));
        __classPrivateFieldGet(this, _EventRunner_events, "f").delete(timestamp);
        result = it.next();
    }
    this.computeNextTickTargetTime();
};
export default EventRunner;
