var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _IOLazyLoader_ioUtil, _IOLazyLoader_boundCallback;
// See: https://web.dev/native-lazy-loading/
const LOADED_VALUE = 'true';
const NATIVE_LAZY_ATTRIBUTE = 'loading';
const NATIVE_LAZY_SUPPORTED = NATIVE_LAZY_ATTRIBUTE in HTMLImageElement.prototype;
const ATTRIBUTE_SRC = 'src';
const ATTRIBUTE_SRCSET = 'srcset';
const DATA_SRCSET = `data-${ATTRIBUTE_SRCSET}`;
const DATA_SRC = `data-${ATTRIBUTE_SRC}`;
const DATA_LAZY_LOADED = 'data-lazy-loaded';
class IOLazyLoader {
    constructor(ioUtil) {
        // Basically a no-op if native lazy loading is supported.
        _IOLazyLoader_ioUtil.set(this, void 0);
        _IOLazyLoader_boundCallback.set(this, this.handleChange.bind(this));
        __classPrivateFieldSet(this, _IOLazyLoader_ioUtil, ioUtil, "f");
    }
    handleChange(change) {
        if (change.isIntersecting) {
            const target = change.target;
            this.loadImage(target);
            return true;
        }
        return false;
    }
    observe(element) {
        if (this.imageHasBeenLoaded(element) || this.elementIsNativeLazy(element)) {
            return;
        }
        __classPrivateFieldGet(this, _IOLazyLoader_ioUtil, "f").observe(element, __classPrivateFieldGet(this, _IOLazyLoader_boundCallback, "f"));
    }
    unobserve(element) {
        __classPrivateFieldGet(this, _IOLazyLoader_ioUtil, "f").unobserve(element);
    }
    loadImage(element) {
        element.setAttribute(DATA_LAZY_LOADED, LOADED_VALUE);
        const src = element.getAttribute(DATA_SRC);
        const srcSet = element.getAttribute(DATA_SRCSET);
        if (srcSet) {
            element.setAttribute(ATTRIBUTE_SRCSET, srcSet);
        }
        if (src) {
            element.setAttribute(ATTRIBUTE_SRC, src);
        }
    }
    imageHasBeenLoaded(element) {
        return element.hasAttribute(DATA_LAZY_LOADED);
    }
    elementIsNativeLazy(element) {
        return NATIVE_LAZY_SUPPORTED && element.getAttribute(NATIVE_LAZY_ATTRIBUTE) === 'lazy';
    }
}
_IOLazyLoader_ioUtil = new WeakMap(), _IOLazyLoader_boundCallback = new WeakMap();
export default IOLazyLoader;
export { NATIVE_LAZY_SUPPORTED };
