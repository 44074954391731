import parseHtml from '@root/javascript/util/html/parser';
class ModalUtils {
    static buildModal(template, transformer) {
        const element = parseHtml(template);
        if (transformer) {
            transformer(element);
        }
        return element;
    }
    static addModal(template, transformer) {
        document.body.appendChild(this.buildModal(template, transformer));
    }
    static addImportedModal(transformer) {
        return ({ default: template }) => new Promise((resolve) => {
            resolve(ModalUtils.addModal(template, transformer));
        });
    }
}
export default ModalUtils;
export const { addModal, addImportedModal, buildModal } = ModalUtils;
