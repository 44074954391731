// An infinite looper that uses requestAnimationFrame.
// This will only run callbacks once per second and aims for the start of a second.
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _CallbackLooper_instances, _CallbackLooper_nextRunTargetTime, _CallbackLooper_pendingTickId, _CallbackLooper_boundOnTick, _CallbackLooper_lastId, _CallbackLooper_callbacks, _CallbackLooper_internalOnTick, _CallbackLooper_internalRequestTick;
import { extractMillisecondsFromTimestamp } from '@root/javascript/util/time-utils';
let staticInstance;
class CallbackLooper {
    constructor() {
        _CallbackLooper_instances.add(this);
        _CallbackLooper_nextRunTargetTime.set(this, 0);
        _CallbackLooper_pendingTickId.set(this, null);
        _CallbackLooper_boundOnTick.set(this, __classPrivateFieldGet(this, _CallbackLooper_instances, "m", _CallbackLooper_internalOnTick).bind(this));
        _CallbackLooper_lastId.set(this, 0);
        _CallbackLooper_callbacks.set(this, {});
    }
    static get main() {
        staticInstance !== null && staticInstance !== void 0 ? staticInstance : (staticInstance = new this());
        return staticInstance;
    }
    remove(callbackId) {
        if (Object.prototype.hasOwnProperty.call(__classPrivateFieldGet(this, _CallbackLooper_callbacks, "f"), callbackId)) {
            delete __classPrivateFieldGet(this, _CallbackLooper_callbacks, "f")[callbackId];
            if (this.size < 1) {
                this.stop();
            }
        }
    }
    add(callback) {
        if (process.env.NODE_ENV === 'development') {
            if (typeof callback !== 'function') {
                console.error('CallbackLooper#add must be provided a function', callback);
            }
        }
        __classPrivateFieldSet(this, _CallbackLooper_lastId, __classPrivateFieldGet(this, _CallbackLooper_lastId, "f") + 1, "f");
        const callbackId = __classPrivateFieldGet(this, _CallbackLooper_lastId, "f");
        __classPrivateFieldGet(this, _CallbackLooper_callbacks, "f")[callbackId] = callback;
        this.start();
        return callbackId;
    }
    get isRunning() {
        return __classPrivateFieldGet(this, _CallbackLooper_pendingTickId, "f") !== null;
    }
    get size() {
        return Object.keys(__classPrivateFieldGet(this, _CallbackLooper_callbacks, "f")).length;
    }
    stop() {
        window.cancelAnimationFrame(__classPrivateFieldGet(this, _CallbackLooper_pendingTickId, "f"));
        __classPrivateFieldSet(this, _CallbackLooper_pendingTickId, null, "f");
    }
    start() {
        if (!this.isRunning) {
            this.stop(); // ensure we don't have two running
            __classPrivateFieldGet(this, _CallbackLooper_instances, "m", _CallbackLooper_internalRequestTick).call(this);
        }
    }
}
_CallbackLooper_nextRunTargetTime = new WeakMap(), _CallbackLooper_pendingTickId = new WeakMap(), _CallbackLooper_boundOnTick = new WeakMap(), _CallbackLooper_lastId = new WeakMap(), _CallbackLooper_callbacks = new WeakMap(), _CallbackLooper_instances = new WeakSet(), _CallbackLooper_internalOnTick = function _CallbackLooper_internalOnTick(timestamp) {
    const now = Date.now();
    if (now >= __classPrivateFieldGet(this, _CallbackLooper_nextRunTargetTime, "f")) {
        // '% 1000 + 1000' is to remove milliseconds from the timestamp so that the next tick
        // will be run as close to the start of the next second as possible.
        //
        // Without it, countdown ticks would noticeably lag behind clock ticks.
        __classPrivateFieldSet(this, _CallbackLooper_nextRunTargetTime, now - extractMillisecondsFromTimestamp(now) + 1000, "f");
        Object.keys(__classPrivateFieldGet(this, _CallbackLooper_callbacks, "f")).forEach((key) => __classPrivateFieldGet(this, _CallbackLooper_callbacks, "f")[key](timestamp, now));
    }
    return __classPrivateFieldGet(this, _CallbackLooper_instances, "m", _CallbackLooper_internalRequestTick).call(this);
}, _CallbackLooper_internalRequestTick = function _CallbackLooper_internalRequestTick() {
    __classPrivateFieldSet(this, _CallbackLooper_pendingTickId, window.requestAnimationFrame(__classPrivateFieldGet(this, _CallbackLooper_boundOnTick, "f")), "f");
    return __classPrivateFieldGet(this, _CallbackLooper_pendingTickId, "f");
};
export default CallbackLooper;
