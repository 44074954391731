var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _IntersectionObserverUtil_threshold, _IntersectionObserverUtil_rootMargin, _IntersectionObserverUtil_observer, _IntersectionObserverUtil_callbacks;
// PolyFill for "isIntersecting"
// https://github.com/WICG/IntersectionObserver/issues/211#issuecomment-309144669
if ('IntersectionObserver' in window
    && 'IntersectionObserverEntry' in window
    && 'intersectionRatio' in window.IntersectionObserverEntry.prototype
    && !('isIntersecting' in IntersectionObserverEntry.prototype)) {
    Object.defineProperty(window.IntersectionObserverEntry.prototype, 'isIntersecting', {
        get() {
            return this.intersectionRatio > 0;
        },
    });
}
class IntersectionObserverUtil {
    constructor({ root = null, threshold = [0.006], rootMargin = '0px', } = {}) {
        _IntersectionObserverUtil_threshold.set(this, void 0);
        _IntersectionObserverUtil_rootMargin.set(this, void 0);
        _IntersectionObserverUtil_observer.set(this, void 0);
        _IntersectionObserverUtil_callbacks.set(this, new WeakMap());
        __classPrivateFieldSet(this, _IntersectionObserverUtil_threshold, threshold, "f");
        __classPrivateFieldSet(this, _IntersectionObserverUtil_rootMargin, rootMargin, "f");
        // the intersection observer
        __classPrivateFieldSet(this, _IntersectionObserverUtil_observer, new IntersectionObserver(this.handleChange.bind(this), {
            root,
            threshold: __classPrivateFieldGet(this, _IntersectionObserverUtil_threshold, "f"),
            rootMargin: __classPrivateFieldGet(this, _IntersectionObserverUtil_rootMargin, "f"),
        }), "f");
    }
    handleChange(changes) {
        changes.forEach((change) => {
            if (change.isIntersecting) {
                const target = change.target;
                const callback = __classPrivateFieldGet(this, _IntersectionObserverUtil_callbacks, "f").get(target);
                if (!callback || callback(change) === true) {
                    __classPrivateFieldGet(this, _IntersectionObserverUtil_callbacks, "f").delete(target);
                    this.unobserve(target);
                }
            }
        });
    }
    observe(element, callback) {
        __classPrivateFieldGet(this, _IntersectionObserverUtil_observer, "f").observe(element);
        if (callback) {
            __classPrivateFieldGet(this, _IntersectionObserverUtil_callbacks, "f").set(element, callback);
        }
    }
    unobserve(element) {
        __classPrivateFieldGet(this, _IntersectionObserverUtil_callbacks, "f").delete(element);
        if (__classPrivateFieldGet(this, _IntersectionObserverUtil_observer, "f") !== null) {
            __classPrivateFieldGet(this, _IntersectionObserverUtil_observer, "f").unobserve(element);
        }
    }
    disconnect() {
        __classPrivateFieldGet(this, _IntersectionObserverUtil_observer, "f").disconnect();
    }
}
_IntersectionObserverUtil_threshold = new WeakMap(), _IntersectionObserverUtil_rootMargin = new WeakMap(), _IntersectionObserverUtil_observer = new WeakMap(), _IntersectionObserverUtil_callbacks = new WeakMap();
export default IntersectionObserverUtil;
