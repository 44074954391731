const internalMillisecondsUntilNextMinute = function () {
    const date = new Date();
    const secondsUntilNextMinute = 60 - date.getSeconds();
    // date.getMilliseconds is subtracted to cause the tick be more accurate
    return secondsUntilNextMinute * 1000 - date.getMilliseconds();
};
const internalExtractMillisecondsFromTimestamp = function (timestamp) {
    return timestamp % 1000;
};
const internalTrimMillisecondsFromTimestamp = function (timestamp) {
    return timestamp - internalExtractMillisecondsFromTimestamp(timestamp);
};
export default internalMillisecondsUntilNextMinute;
export const millisecondsUntilNextMinute = internalMillisecondsUntilNextMinute;
export const trimMillisecondsFromTimestamp = internalTrimMillisecondsFromTimestamp;
export const extractMillisecondsFromTimestamp = internalExtractMillisecondsFromTimestamp;
export const hasPrintableFormatOptions = (options) => {
    if (options.dateStyle !== undefined) {
        return true;
    }
    if (options.timeStyle !== undefined) {
        return true;
    }
    if (options.weekday !== undefined) {
        return true;
    }
    if (options.era !== undefined) {
        return true;
    }
    if (options.year !== undefined) {
        return true;
    }
    if (options.month !== undefined) {
        return true;
    }
    if (options.day !== undefined) {
        return true;
    }
    if (options.hour !== undefined) {
        return true;
    }
    if (options.minute !== undefined) {
        return true;
    }
    if (options.second !== undefined) {
        return true;
    }
    return false;
};
