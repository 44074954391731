export default function parseHTML(markup) {
    if (markup.toLowerCase().trim().indexOf('<!doctype') === 0) {
        const doc = document.implementation.createHTMLDocument('');
        doc.documentElement.innerHTML = markup;
        return doc;
    }
    if ('content' in document.createElement('template')) {
        // Template tag exists!
        const el = document.createElement('template');
        el.innerHTML = markup;
        return el.content;
    }
    // Template tag doesn't exist!
    const docfrag = document.createDocumentFragment();
    const el = document.createElement('body');
    el.innerHTML = markup;
    for (let i = 0; el.childNodes.length > 0;) {
        docfrag.appendChild(el.childNodes[i]);
    }
    return docfrag;
}
