var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _instances, _mapping, _register;
import EventRunner from '@root/javascript/common/event-runner';
const Runner = EventRunner.global;
export default class {
    constructor() {
        _instances.add(this);
        _mapping.set(this, {});
    }
    schedule(key, callback, delayMillis) {
        this.cancel(key);
        if (process.env.NODE_ENV === 'development') {
            console.log('scheduling', key, callback, delayMillis);
        }
        __classPrivateFieldGet(this, _instances, "m", _register).call(this, key, Runner.runInMillis(callback, delayMillis));
    }
    scheduleAt(key, callback, timestamp) {
        this.cancel(key);
        if (process.env.NODE_ENV === 'development') {
            console.log('scheduling', key, callback, timestamp);
        }
        __classPrivateFieldGet(this, _instances, "m", _register).call(this, key, Runner.runAt(callback, timestamp));
    }
    execute(key) {
        if (process.env.NODE_ENV === 'development') {
            console.log('executing', key);
        }
        this.cancel(key, true);
    }
    cancel(key, executePending = false) {
        if (process.env.NODE_ENV === 'development') {
            console.log('canceling', key, executePending);
        }
        if (this.has(key)) {
            const id = this.get(key);
            delete __classPrivateFieldGet(this, _mapping, "f")[key];
            if (executePending) {
                Runner.runCallback(id);
            }
            else {
                Runner.cancel(id);
            }
        }
    }
    cancelAll() {
        Object.keys(__classPrivateFieldGet(this, _mapping, "f")).forEach((key) => this.cancel(key));
    }
    get(key) {
        return __classPrivateFieldGet(this, _mapping, "f")[key];
    }
    has(key) {
        return Object.prototype.hasOwnProperty.call(__classPrivateFieldGet(this, _mapping, "f"), key);
    }
}
_mapping = new WeakMap(), _instances = new WeakSet(), _register = function _register(key, id) {
    if (id) {
        __classPrivateFieldGet(this, _mapping, "f")[key] = id;
    }
};
