class UrlUtils {
    static replaceQueryParam(uri, key, value) {
        // remove the hash part before operating on the uri
        const i = uri.indexOf('#');
        const hash = i === -1 ? '' : uri.substring(i);
        let resultUri = i === -1 ? uri : uri.substring(0, i);
        const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
        const separator = resultUri.includes('?') ? '&' : '?';
        if (value === '' || value === null) {
            // remove key-value pair if value is empty
            resultUri = resultUri.replace(new RegExp(`([?&]?)${key}=[^&]*`, 'i'), '');
            if (resultUri.slice(-1) === '?') {
                resultUri = resultUri.slice(0, -1);
            }
            // replace first occurrence of & by ? if no ? is present
            if (resultUri.includes('?')) {
                resultUri = resultUri.replace(/&/, '?');
            }
        }
        else if (resultUri.match(re)) {
            resultUri = resultUri.replace(re, `$1${key}=${value}$2`);
        }
        else {
            resultUri = `${resultUri}${separator}${key}=${value}`;
        }
        return resultUri + hash;
    }
    static replaceQueryParams(uri, params) {
        const keys = Object.keys(params);
        const { length } = keys;
        let result = uri;
        for (let i = 0; i < length; i += 1) {
            const name = keys[i];
            result = UrlUtils.replaceQueryParam(result, name, params[name]);
        }
        return result;
    }
    static removeQueryParams(uri, ...params) {
        if (!uri.includes('?')) {
            return uri;
        }
        const parsed = new URL(uri);
        params.forEach((param) => {
            parsed.searchParams.delete(param);
        });
        return parsed.toString();
    }
}
export default UrlUtils;
export const { replaceQueryParam, replaceQueryParams, removeQueryParams } = UrlUtils;
